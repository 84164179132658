import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-compare-cases',
  templateUrl: './compare-cases.component.html',
  styleUrl: './compare-cases.component.css',
})
export class CompareCasesComponent {
  caseIds: number[] = [];
  comparisonResult: any;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    const caseIdsParam = this.route.snapshot.queryParamMap.get('caseIds');
    if (caseIdsParam) {
      this.caseIds = caseIdsParam.split(',').map((id) => Number(id));
      console.log(this.caseIds.length);
      if (this.caseIds.length <= 1) {
        this.router.navigate(['chat']);
      }
    } else {
      this.router.navigate(['chat']);
    }
  }
}
