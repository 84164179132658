import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // 1. Capture the token from the URL if it's there (happens right after login)
    const token = route.queryParams['token'];
    if (token) {
      console.log('Token in URL (AuthGuard):', token);
      this.authService.setToken(token); // Store the token

      // Redirect to chat (or another route) after storing the token and fetching the profile
      this.router.navigate(['/chat']);
      return false; // Block navigation to the current route with the token in the URL
    }

    // 2. Protect routes conditionally based on login status
    const loggedIn = this.authService.isLoggedIn();

    // If user is trying to access the login page but is already logged in, redirect to account
    if (state.url === '/login' && loggedIn) {
      this.router.navigate(['/account']); // Redirect to account if already logged in
      return false; // Prevent navigation to login
    }

    // If user is trying to access the account page but is not logged in, redirect to login
    if (state.url === '/account' && !loggedIn) {
      this.router.navigate(['/login']); // Redirect to login if not logged in
      return false; // Prevent navigation to account
    }

    // If the route is protected and the user is not logged in, redirect to login
    const protectedRoutes = ['/account']; // Add other protected routes here
    // const protectedRoutes = ['/chat', '/account']; // Add other protected routes here
    if (protectedRoutes.includes(state.url) && !loggedIn) {
      this.router.navigate(['/login']); // Redirect to login page
      return false; // Block access to the protected route
    }

    // Allow access to other routes
    return true;
  }
}
