import { Injectable, ElementRef, EventEmitter } from '@angular/core';
import { marked } from 'marked';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from './api.service';

declare var bootstrap: any;

@Injectable({
  providedIn: 'root',
})
export class ChatHelperService {
  checkboxLimitReached: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router, private apiService: ApiService) {}

  animateCards(
    cards: any[],
    cardList: HTMLElement,
    messageRef: any,
    scrollContainer: ElementRef | null,
    onComplete: () => void
  ) {
    cards.forEach((card, index) => {
      setTimeout(() => {
        if (messageRef.stopped) return;

        const listItem = document.createElement('li');
        listItem.style.marginBottom = '15px';

        const cardElement = this.createCardElement(card);
        cardElement.classList.add('line-reveal');
        listItem.appendChild(cardElement);
        cardList.appendChild(listItem);

        this.scrollToBottom(scrollContainer);

        if (index === cards.length - 1) {
          onComplete();
        }
      }, index * 2000);
    });
  }

  createCardElement(card: any): HTMLElement {
    const cardDiv = document.createElement('div');
    cardDiv.style.backgroundColor = 'transparent';
    cardDiv.style.color = '#f0f0f0';
    cardDiv.style.border = 'none';
    cardDiv.style.paddingLeft = '1rem';
    cardDiv.style.borderRadius = '4px';
    cardDiv.style.marginBottom = '1rem';
    cardDiv.style.lineHeight = '1.6';
    let shortSummaryMarked = marked.parse(card.short_summary);

    const innerHTML = `
      <p style="margin: 0; line-height: 1.6;">
        <strong>Case Name:</strong> ${card.case_name}
      </p>
      <p style="margin: 0; line-height: 1.6;">
        <strong>Court:</strong> ${card.court_full_name}
      </p>
      <p style="margin: 0; line-height: 1.6;">
        <strong>Year:</strong> ${new Date(card.date_filed).getFullYear()}
      </p>
      <p style="margin: 0; line-height: 1.6;">
        <strong>Docket Number:</strong> ${card.docket_number || 'N/A'}
      </p>
      <p style="margin: 0; line-height: 1.6;">
        <strong>Precedential Status:</strong> ${card.precedential_status}
      </p>
      <p style="margin: 0; line-height: 1.6;">
        <strong>Summary:</strong> ${shortSummaryMarked}
      </p>
      <p style="margin: 0; line-height: 1.6;">
         <a href="document-chat?opinionId=${
           card.id
         }" target="_blank" style="text-decoration: underline;">Chat with this document</a>
      </p>
    `;

    cardDiv.innerHTML = innerHTML;
    return cardDiv;
  }

  animateTable(
    table: any[],
    targetElement: HTMLElement,
    onComplete: () => void
  ) {
    const tableWrapper = document.createElement('div');
    tableWrapper.classList.add('table-responsive');

    const tableElement = document.createElement('table');
    tableElement.classList.add(
      'table',
      'table-bordered',
      'table-hover',
      'table-striped',
      'table-dark',
      'line-reveal'
    );

    const tableHeader = `
      <thead>
        <tr>
          <th>Case Name</th>
          <th>Court</th>
          <th>Date Filed</th>
          <th>Docket Number</th>
          <th>Status</th>
          <th>Summary (Click to Expand)</th>
          <th>Pdf</th>
          <th>Compare</th>
        </tr>
      </thead>
    `;
    tableElement.innerHTML = tableHeader;

    const tableBody = document.createElement('tbody');

    let checkCounter = 0;
    const maxChecks = 10;

    table.forEach((row) => {
      const pdfLink = `document-chat?opinionId=${row.id}`;
      const parsedFullSummary = marked(row.full_summary);
      const rowElement = document.createElement('tr');
      rowElement.innerHTML = `
        <td>${row.case_name || row.case_name_full || 'N/A'}</td>
        <td>${row.court_full_name}</td>
        <td>${new Date(row.date_filed).toLocaleDateString()}</td>
        <td>${row.docket_number || 'N/A'}</td>
        <td>${row.precedential_status}</td>
        <td><span data-tooltip="${
          row.short_summary
        }">${parsedFullSummary}</span></td>
        <td><a href="${pdfLink}" target="_blank">view</a></td>
        <td>
          <input type="checkbox" id="checkbox-${row.id}" data-id="${row.id}">
        </td>
      `;

      const checkbox = rowElement.querySelector(
        `#checkbox-${row.id}`
      ) as HTMLInputElement;

      // Automatically check only the first 10 checkboxes
      if (checkCounter < maxChecks) {
        checkbox.checked = true;
        checkCounter++;
      }
      checkbox.addEventListener('click', (event) => {
        const target = event.target as HTMLInputElement;
        const rowId = target.dataset['id'];
        console.log('Checkbox clicked. Row ID:', rowId);

        if (target.checked) {
          if (checkCounter >= maxChecks) {
            // Prevent checking and log a message if limit is exceeded
            target.checked = false;
            console.log('Cannot select more than 10 checkboxes.');
            this.checkboxLimitReached.emit();
          } else {
            checkCounter++;
          }
        } else {
          checkCounter--;
        }
      });

      tableBody.appendChild(rowElement);
    });

    tableElement.appendChild(tableBody);
    this.truncateTableCells(tableElement);
    tableWrapper.appendChild(tableElement);
    targetElement.appendChild(tableWrapper);

    onComplete();
  }

  truncateTableCells(targetElement: HTMLElement) {
    const tableCells = targetElement.querySelectorAll('td');

    tableCells.forEach((cell) => {
      const originalHTML = cell.innerHTML;
      const plainTextContent = cell.textContent || '';
      const tooltipText =
        cell.querySelector('span')?.getAttribute('data-tooltip') || '';

      if (plainTextContent.length > 50) {
        cell.setAttribute('data-original-html', originalHTML);
        if (tooltipText) {
          cell.setAttribute('title', tooltipText);
        }

        const truncatedText = `${plainTextContent.slice(0, 50)}...`;
        cell.innerHTML = truncatedText;
        cell.style.cursor = 'pointer';

        cell.addEventListener('click', () => {
          const isTruncated = cell.textContent?.endsWith('...');
          if (isTruncated) {
            cell.innerHTML = cell.getAttribute('data-original-html')!;
            cell.removeAttribute('title');
          } else {
            cell.innerHTML = truncatedText;
            if (tooltipText) {
              cell.setAttribute('title', tooltipText);
            }
          }
        });
      }
    });
  }

  //-------------------------------------
  // ---------- Scroll methods ----------
  //-------------------------------------

  isAtBottom(scrollContainer: ElementRef | null): boolean {
    if (!scrollContainer) return false;
    const threshold = 300;
    const position =
      scrollContainer.nativeElement.scrollTop +
      scrollContainer.nativeElement.offsetHeight;
    const height = scrollContainer.nativeElement.scrollHeight;

    return height - position <= threshold;
  }

  scrollToBottom(scrollContainer: ElementRef | null): void {
    if (scrollContainer && this.isAtBottom(scrollContainer)) {
      setTimeout(() => {
        scrollContainer.nativeElement.scrollTop =
          scrollContainer.nativeElement.scrollHeight;
      }, 100);
    }
  }

  //-------------------------------------
  // ------- Other helper methods -------
  //-------------------------------------

  triggerTooltips() {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new bootstrap.Tooltip(tooltipTriggerEl);
    });
  }

  startNewChat(): void {
    if (localStorage.getItem('authToken') == null) {
      this.router.navigate(['/chat']).then(() => {
        window.location.reload();
      });
    } else {
      this.apiService.resetCurrentMessages().subscribe(
        (response) => {
          this.router.navigate(['/chat']).then(() => {
            window.location.reload();
          });
        },
        (error) => {
          console.error('Error resetting messages:', error);
        }
      );
    }
  }

  copyAnswer(message: any) {
    const apiElement = document.getElementById(`api-${message.uuid}`);
    const apiHTML = apiElement ? apiElement.innerText : '';

    const textarea = document.createElement('textarea');
    textarea.value = apiHTML;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  waitForElement(id: string, callback: Function, interval = 100) {
    const element = document.getElementById(id);
    if (element) {
      callback(element);
    } else {
      setTimeout(() => this.waitForElement(id, callback, interval), interval);
    }
  }

  addParagraph(
    parent: HTMLElement,
    content: string,
    styles: { [key: string]: string }
  ) {
    const paragraph = document.createElement('p');
    paragraph.textContent = content;

    Object.entries(styles).forEach(([key, value]) => {
      (paragraph.style as any)[key] = value;
    });

    paragraph.classList.add('line-reveal');
    parent.appendChild(paragraph);
  }
}
