import {
  Component,
  Renderer2,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ChatHelperService } from '../../services/chat-helper.service';
import { marked } from 'marked';
import { v4 as uuidv4 } from 'uuid';
import { interval, NotFoundError, Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

declare var bootstrap: any;

interface SearchResult {
  role: string;
  content: string;
  refusal?: string;
}

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent {
  showGuestPopup: boolean = false;
  showFilterPopup: boolean = false;
  showComparePopup: boolean = false;
  sentMessage: string = '';
  showDialogBox: boolean = false;
  messages: {
    stopped: boolean;
    sentMessage: string;
    resultFromBackend: any;
    uuid: string;
    showCompareButton: boolean;
    loading: boolean;
  }[] = [];
  loading: boolean = false;
  activeUUID: string | null = null;
  apiResponseReceived: boolean = false;
  dateRange: { from: number | null; to: number | null } = {
    from: null,
    to: null,
  };
  selectedCourts: string[] = [];
  private messageCounter: number = 0;
  private readonly messageThreshold: number = 5;
  showSmallScreenButtons = false;
  isDocChat = false;
  isCompareCase = false;
  private checkboxLimitSubscription: Subscription | null = null;

  publicResDone: boolean = false;
  apiResDone: boolean = false;

  @Input() opinionId: number | null = null;
  @Input() caseIds: number[] = [];

  @ViewChild('scrollContainer') private scrollContainer: ElementRef | null =
    null;

  constructor(
    private apiService: ApiService,
    private chatHelper: ChatHelperService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.handleChatInit();
    this.handleAuth();
    this.loadStateFromStorage();
  }

  ngAfterViewInit(): void {
    this.chatHelper.triggerTooltips();
  }

  ngOnDestroy(): void {
    this.checkboxLimitSubscription?.unsubscribe();
  }

  // ----------------------------------
  // ------- Methods for ngInit -------
  // ----------------------------------

  handleChatInit() {
    this.checkboxLimitSubscription =
      this.chatHelper.checkboxLimitReached.subscribe(() => {
        this.handleComparePopup();
      });
    if (this.opinionId) {
      this.isDocChat = true;
    }
    if (this.caseIds.length >= 2) {
      this.isCompareCase = true;
      this.applyRevealEffectCompare(this.caseIds);
    }
  }

  handleAuth() {
    const currentUrl = this.router.url;
    if (
      localStorage.getItem('authToken') &&
      !currentUrl.includes('/document-chat') &&
      !currentUrl.includes('/compare-cases')
    ) {
      console.log('Loading previous message...');
      this.loadPreviousMessages();
    } else if (
      !localStorage.getItem('authToken') &&
      !currentUrl.includes('/document-chat') &&
      !currentUrl.includes('/compare-cases')
    ) {
      this.handleGuestPopup();
    }
  }

  // ----------------------------------
  // ----- Methods to handle chat -----
  // ----------------------------------

  onMessageReceived(message: string) {
    const uuid = uuidv4();

    this.sentMessage = message;
    this.showDialogBox = true;

    const newMessage = {
      sentMessage: message,
      resultFromBackend: '',
      uuid: uuid,
      stopped: false,
      showCompareButton: false,
      loading: true,
    };

    this.messages.push(newMessage);
    this.chatHelper.scrollToBottom(this.scrollContainer);

    this.activeUUID = uuid;
    this.apiResponseReceived = false;

    this.getCompletion(message, newMessage, uuid);

    if (!localStorage.getItem('authToken')) {
      this.messageCounter++;

      if (this.messageCounter >= this.messageThreshold) {
        this.handleGuestPopup();
        this.messageCounter = 0;
      }
    }
  }

  handleCompletion(
    prompt: string,
    messageUUID: string,
    messageRef: any,
    shouldScroll: boolean = true,
    payload: {
      courts: string[];
      dateRange: { from: number | null; to: number | null };
    }
  ) {
    this.loading = true;

    const timeoutDuration = 60000;
    const timeoutId = setTimeout(() => {
      const foundMessage = this.messages.find((m) => m.uuid === messageUUID);
      if (foundMessage && foundMessage.loading) {
        foundMessage.resultFromBackend = 'Request timed out.';
        this.loading = false;
        this.activeUUID = null;
        this.cdRef.detectChanges();
        if (shouldScroll) {
          this.chatHelper.scrollToBottom(this.scrollContainer);
        }
      }
    }, timeoutDuration);
    this.startFeedbackLoop();

    // Send a req for regular chat.
    if (!this.isDocChat) {
      // API req
      this.apiService
        .postCompletionAPI({
          prompt,
          uuid: messageUUID,
          ...payload,
        })
        .subscribe(
          (data: any) => {
            clearTimeout(timeoutId);
            console.log('data: ', data);

            // Get public info
            const publicInfo =
              data.searchResults && data.searchResults.publicInfo.length >= 2
                ? data.searchResults.publicInfo.find(
                    (result: SearchResult) => result.role === 'assistant'
                  )?.content || ''
                : '';

            // Is public info valid
            const isPublicEmpty = publicInfo.includes('NOT_FOUND');

            // Get the cards and table data
            const resultContent = data.searchResults.searchResult;
            const cards = resultContent.slice(0, 3);
            const tableData = resultContent;

            // Get the answer info
            const answerContent = data.searchResults.answer;

            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );

            if (foundMessage && !foundMessage.stopped) {
              foundMessage.loading = false;
              foundMessage.resultFromBackend = resultContent;
              this.cdRef.detectChanges();
              if (isPublicEmpty) {
                this.applyRevealEffectApi(
                  foundMessage,
                  { cards, table: tableData, answer: answerContent },
                  'api'
                );
              } else {
                this.applyRevealEffectApi(
                  foundMessage,
                  {
                    cards,
                    table: tableData,
                    answer: answerContent,
                    publicInfo,
                  },
                  'api'
                );
              }
              if (resultContent.length >= 1) {
                foundMessage.showCompareButton = resultContent.length > 1;
              }
            }
            // else if (foundMessage && foundMessage.stopped) {
            //   this.cdRef.detectChanges();
            // }

            this.apiResponseReceived = true;
          },
          (error: any) => {
            clearTimeout(timeoutId);
            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );
            if (foundMessage) {
              this.apiResponseReceived = true;
              foundMessage.resultFromBackend =
                'An error occurred with the API request.';
            }
            this.stopGeneration();
          }
        );
    } else {
      // Send a req for document chat.
      this.apiService
        .postCompletionAPIdoc({
          prompt,
          uuid: messageUUID,
          ...payload,
        })
        .subscribe(
          (data: any) => {
            clearTimeout(timeoutId);
            console.log('data: ', data);
            var resultContent =
              data.searchResults && data.searchResults.length >= 2
                ? data.searchResults.find(
                    (result: SearchResult) => result.role === 'assistant'
                  )?.content || ''
                : '';

            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );
            if (foundMessage && !foundMessage.stopped) {
              foundMessage.loading = false;
              foundMessage.resultFromBackend = resultContent;
              this.cdRef.detectChanges();
              this.applyRevealEffectPublic(foundMessage, resultContent, 'api');
              this.cdRef.detectChanges();
            }
            // else if (foundMessage && foundMessage.stopped) {
            //   this.cdRef.detectChanges();
            // }
            this.apiResponseReceived = true;
            // if (this.opinionId) {
            //   console.log('Stop waiting for a public response...');
            // }
          },
          (error: any) => {
            clearTimeout(timeoutId);
            const foundMessage = this.messages.find(
              (m) => m.uuid === messageUUID
            );
            if (foundMessage) {
              this.apiResponseReceived = true;
              foundMessage.resultFromBackend =
                'An error occurred with the API request.';
            }
            this.stopGeneration();
          }
        );
    }
  }

  getCompletion(prompt: string, messageRef: any, messageUUID: string) {
    console.log('court filters: ', this.selectedCourts);
    console.log('date range: ', this.dateRange);
    const payload = {
      courts: this.selectedCourts,
      dateRange: this.dateRange,
      opinionId: this.opinionId,
    };

    this.handleCompletion(prompt, messageUUID, messageRef, true, payload);
  }

  startNewChat(): void {
    this.chatHelper.startNewChat();
  }

  copyAnswer(message: any) {
    this.chatHelper.copyAnswer(message);
  }

  stopGeneration() {
    const activeMessage = this.messages.find((m) => m.uuid === this.activeUUID);

    if (activeMessage && this.loading === true) {
      activeMessage.stopped = true;

      this.loading = false;
      this.activeUUID = null;
      this.cdRef.detectChanges();
      this.apiResponseReceived = false;
      this.stopFeedbackLoop();
    }
  }

  handleSendClick() {
    if (this.loading) {
      this.stopGeneration();
    } else {
      this.onMessageReceived(this.sentMessage);
    }
  }

  handleCardClick(cardText: string) {
    this.onMessageReceived(cardText);
    const bodyElement = document.body;
    // this.renderer.setStyle(bodyElement, 'display', 'block');
  }

  // -------------------------------------
  // --- Methods to get previous chats ---
  // -------------------------------------

  loadPreviousMessages(): void {
    this.apiService.getCurrentMessages().subscribe(
      (data) => {
        console.log(data);

        var publicInfo =
          data.previousMessages.publicInfo &&
          data.previousMessages.publicInfo.length >= 2
            ? data.previousMessages.publicInfo.find(
                (result: SearchResult) => result.role === 'assistant'
              )?.content || ''
            : '';
        if (publicInfo.includes('NOT_FOUND')) {
          publicInfo = null;
        }
        console.log(publicInfo);
        const prompt =
          data.previousMessages.publicInfo &&
          data.previousMessages.publicInfo.length >= 2
            ? data.previousMessages.publicInfo.find(
                (result: SearchResult) => result.role === 'user'
              )?.content || ''
            : '';
        console.log(prompt);
        const answerContent = data.previousMessages.answer;
        console.log(answerContent);

        const resultContent = data.previousMessages.searchResult;
        console.log(resultContent);
        const cards = resultContent.slice(0, 3);
        const tableData = resultContent;

        this.showDialogBox = true;
        this.startFeedbackLoop();
        this.loading = true;
        var previousUUID = uuidv4();
        this.activeUUID = previousUUID;

        const newMessage = {
          sentMessage: prompt,
          resultFromBackend: resultContent,
          uuid: previousUUID,
          stopped: false,
          showCompareButton: true,
          loading: false,
        };
        this.messages.unshift(newMessage);

        console.log('test1');

        setTimeout(async () => {
          const apiElement = document.getElementById(`api-${previousUUID}`);
          if (apiElement) console.log('Found the element.');
          else
            console.log(
              'Can not found the element with the id: ',
              `api-${previousUUID}`
            );

          this.chatHelper.waitForElement(
            `api-${previousUUID}`,
            async (apiElement: any) => {
              console.log('Loading the previous messsage any minute now...');
              this.apiResponseReceived = true;
              this.applyRevealEffectApi(
                newMessage,
                { cards, table: tableData, answer: answerContent, publicInfo },
                'api'
              );
            }
          );
        }, 1);
      },
      (error) => {
        console.error('Failed to load previous messages:', error);
      }
    );
  }

  //-------------------------------------
  //------- Feedback text methods -------
  //-------------------------------------

  showFeedback: boolean = false;
  feedbackMessage: string = '';
  feedbackSubscription!: Subscription;
  feedbackMessages: string[] = [
    'Checking cache...',
    'Processing your query...',
    'Searching for relevant cases...',
    'Finding the best matches...',
    'Refining the results...',
    'Checking additional sources...',
    'Analyzing case details...',
    'Compiling your results...',
    'Almost there...',
  ];

  triggerFade: boolean = false;

  startFeedbackLoop() {
    this.showFeedback = true;
    let index = 0;

    this.feedbackMessage = this.feedbackMessages[index];
    this.triggerFade = true;

    this.feedbackSubscription = interval(5000).subscribe(() => {
      this.triggerFade = false;
      setTimeout(() => {
        if (index < this.feedbackMessages.length - 1) {
          this.feedbackMessage = this.feedbackMessages[index];
          this.triggerFade = true;
          index++;
        } else {
          this.feedbackMessage =
            this.feedbackMessages[this.feedbackMessages.length - 1];
          this.triggerFade = true;
          this.feedbackSubscription.unsubscribe();
        }
      }, 100);
    });
  }

  stopFeedbackLoop() {
    if (this.feedbackSubscription) {
      this.feedbackSubscription.unsubscribe();
    }
    this.showFeedback = false;
    this.feedbackMessage = '';
    this.triggerFade = false;
  }

  //-------------------------------------
  //----------- Pop-up Methods ----------
  //-------------------------------------

  handleGuestPopup() {
    this.showGuestPopup = !this.showGuestPopup;
  }

  login() {
    this.router.navigate(['/login']);
  }

  handleFilterPopup() {
    this.showFilterPopup = !this.showFilterPopup;
  }

  handleComparePopup() {
    this.showComparePopup = !this.showComparePopup;
  }

  // ------------------------------------
  // ---------- Resize Methods ----------
  // ------------------------------------

  handleSmallScreenButtons() {
    this.showSmallScreenButtons = !this.showSmallScreenButtons;
  }

  // ------------------------------------
  // --------- Filter Settings ----------
  // ------------------------------------

  onDateRangeChange(dateRange: { from: number | null; to: number | null }) {
    this.dateRange = dateRange;
    localStorage.setItem('dateRange', JSON.stringify(this.dateRange));
    console.log('Updated date range:', this.dateRange);
  }

  onSelectedCourtsChange(selectedCourts: string[]) {
    this.selectedCourts = selectedCourts;
    localStorage.setItem('selectedCourts', JSON.stringify(this.selectedCourts));
    console.log('Updated selected courts:', this.selectedCourts);
  }

  loadStateFromStorage() {
    const savedCourts = localStorage.getItem('selectedCourts');
    const savedDateRange = localStorage.getItem('dateRange');

    if (savedCourts) {
      this.selectedCourts = JSON.parse(savedCourts);
    }

    if (savedDateRange) {
      this.dateRange = JSON.parse(savedDateRange);
    }
  }

  // ------------------------------------
  // -------- Animation Effects ---------
  // ------------------------------------

  async applyRevealEffectPublic(
    messageRef: any,
    content: string,
    type: 'api' | 'public'
  ) {
    this.chatHelper.triggerTooltips();

    const htmlContent = await marked(content);
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;

    const targetId = `${type}-${messageRef.uuid}`;
    const targetElement = document.getElementById(targetId);

    if (targetElement) {
      const allElements = Array.from(
        tempDiv.querySelectorAll('p, li, h1, h2, h3, blockquote, ol, ul')
      );

      const outermostElements = allElements.filter(
        (el) =>
          !allElements.some((otherEl) => otherEl !== el && otherEl.contains(el))
      );

      let completedAnimations = 0;
      this.stopFeedbackLoop();

      outermostElements.forEach((element, index) => {
        setTimeout(() => {
          if (messageRef.stopped) {
            return;
          }
          const animatedElement = element.cloneNode(true) as HTMLElement;
          animatedElement.classList.add('line-reveal');
          targetElement.appendChild(animatedElement);
          this.chatHelper.scrollToBottom(this.scrollContainer);

          animatedElement.addEventListener('animationend', () => {
            completedAnimations += 1;

            if (completedAnimations === outermostElements.length) {
              if (this.apiResponseReceived) {
                this.stopGeneration();
              } else {
                this.startFeedbackLoop();
              }
            }
          });
        }, index * 2000);
      });
    }
  }

  async applyRevealEffectApi(
    messageRef: any,
    content: { cards: any[]; table: any[]; answer?: any; publicInfo?: any },
    type: 'api' | 'public'
  ) {
    console.log('test 2');
    this.chatHelper.triggerTooltips();
    const { cards, table, answer, publicInfo } = content;
    const targetId = `${type}-${messageRef.uuid}`;
    console.log('targetId: ', targetId);
    const targetElement = document.getElementById(targetId);
    if (!targetElement) return;

    console.log('test 3');

    if (publicInfo) {
      this.addTitle(targetElement, 'Online Resources', {
        color: '#f0f0f0',
      });
      this.addParagraph(targetElement, await marked(publicInfo), {
        color: '#f0f0f0',
      });
    }

    this.addTitle(targetElement, 'Information from Our Records', {
      color: '#f0f0f0',
    });

    if (answer) {
      console.log('test 4');

      this.addParagraph(targetElement, await marked(answer), {
        color: '#f0f0f0',
      });
    }

    if (cards.length > 0) {
      console.log('test 5');

      this.addIntroParagraph(targetElement);
      this.animateCards(cards, targetElement, messageRef, table);
    } else if (table.length > 0) {
      this.animateTable(table, targetElement);
    } else {
      this.stopGeneration();
    }
  }

  // -----------------------------------
  // --------- Helper methods ----------
  // -----------------------------------

  addParagraph(
    targetElement: HTMLElement,
    content: string,
    styles: { [key: string]: string }
  ) {
    const paragraph = document.createElement('p');
    paragraph.innerHTML = content;

    Object.entries(styles).forEach(([key, value]) => {
      (paragraph.style as any)[key] = value;
    });

    paragraph.classList.add('line-reveal');
    targetElement.appendChild(paragraph);
  }

  addTitle(
    targetElement: HTMLElement,
    content: string,
    styles: { [key: string]: string }
  ) {
    const title = document.createElement('h3');
    title.innerHTML = content;

    Object.entries(styles).forEach(([key, value]) => {
      (title.style as any)[key] = value;
    });

    title.classList.add('line-reveal');
    targetElement.appendChild(title);
  }

  addIntroParagraph(targetElement: HTMLElement) {
    const introText = `Here are the relevant case document(s). You can quickly understand them by reading the summaries or interacting with the document directly. To compare cases, select the ones you’re interested in and click the "Compare" button.`;

    const introParagraph = document.createElement('p');
    introParagraph.textContent = introText;
    introParagraph.style.color = '#f0f0f0';
    introParagraph.classList.add('line-reveal');
    targetElement.appendChild(introParagraph);

    introParagraph.insertAdjacentHTML(
      'beforeend',
      ` <button 
          class="link-primary"
          style="background-color: transparent; border: none; text-decoration:underline; cursor: pointer;" 
          id="filter-button-2">
          Filter by court name.
        </button>`
    );

    const filterButton = introParagraph.querySelector('#filter-button-2');
    filterButton?.addEventListener('click', () => {
      this.handleFilterPopup();
    });
  }

  animateCards(
    cards: any[],
    targetElement: HTMLElement,
    messageRef: any,
    table: any[]
  ) {
    const cardList = document.createElement('ol');
    cardList.style.padding = '0';
    cardList.style.margin = '0';
    cardList.style.listStyleType = 'decimal';

    targetElement.appendChild(cardList);

    this.stopFeedbackLoop();
    this.chatHelper.animateCards(
      cards,
      cardList,
      messageRef,
      this.scrollContainer,
      () => {
        if (table.length > 0) {
          this.animateTable(table, targetElement);
        } else if (this.apiResponseReceived) {
          this.stopGeneration();
        } else {
          this.startFeedbackLoop();
        }
      }
    );
  }

  animateTable(table: any[], targetElement: HTMLElement) {
    const tableHeading = document.createElement('h3');
    tableHeading.textContent = 'More Cases...';
    tableHeading.style.margin = '1rem 0';
    tableHeading.style.color = '#f0f0f0';
    targetElement.appendChild(tableHeading);

    this.chatHelper.animateTable(table, targetElement, () => {
      if (this.apiResponseReceived) {
        console.log('animate table test 1');
        this.stopGeneration();
      } else {
        console.log('animate table test 1');

        this.startFeedbackLoop();
      }
    });
  }

  // ------------------------------------
  // ------- Case Compare Methods -------
  // ------------------------------------

  loadCompareCasePage(uuid: string) {
    const responseContainer = document.getElementById(`api-${uuid}`);
    if (!responseContainer) {
      console.error(`No response container found for UUID: ${uuid}`);
      return;
    }
    const checkboxes = responseContainer.querySelectorAll(
      'input[type="checkbox"]:checked'
    );
    const selectedIds = Array.from(checkboxes).map((checkbox: any) =>
      checkbox.getAttribute('data-id')
    );

    if (selectedIds.length < 2) {
      console.log('Please select at least two cases to compare.');
      return;
    }
    const url = this.router
      .createUrlTree(['/compare-cases'], {
        queryParams: { caseIds: selectedIds.join(',') },
      })
      .toString();
    window.open(url, '_blank');
  }

  async applyRevealEffectCompare(caseIds: number[]) {
    var compareUUID = uuidv4();
    this.showDialogBox = true;
    this.activeUUID = compareUUID;
    this.startFeedbackLoop();
    this.loading = true;

    const newMessage = {
      sentMessage: 'Compare the given cases.',
      resultFromBackend: '',
      uuid: compareUUID,
      stopped: false,
      showCompareButton: false,
      loading: false,
    };
    this.messages.unshift(newMessage);

    this.apiService.compareCases(caseIds).subscribe(
      (response) => {
        this.stopFeedbackLoop();

        console.log('Comparison result:', response.result);

        this.messages[0].resultFromBackend = response.result;

        setTimeout(async () => {
          const apiElement = document.getElementById(`api-${compareUUID}`);
          if (apiElement) console.log('Found the element.');
          else
            console.log(
              'Can not found the element with the id: ',
              `api-${compareUUID}`
            );

          this.chatHelper.waitForElement(
            `api-${compareUUID}`,
            async (apiElement: any) => {
              console.log('Changing the the inner html...');
              apiElement.classList.add('line-reveal');
              apiElement.innerHTML = await marked.parse(response.result);
            }
          );
        }, 1);

        this.loading = false;
        this.chatHelper.scrollToBottom(this.scrollContainer);
        this.cdRef.detectChanges();
      },
      (error) => {
        console.error('Error during comparison:', error);
      }
    );
  }
}
