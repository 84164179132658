<main
  class="main"
  [class.scrollable]="showDialogBox"
  [class.card-view-limited]="!showDialogBox"
  #scrollContainer
>
  <div class="container-fluid">
    <div class="row">
      <button
        (click)="startNewChat()"
        class="new-chat-button d-none d-md-flex"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-bs-title="New Chat"
      >
        <svg class="new-chat-icon">
          <use xlink:href="icons.svg#new-chat-icon"></use>
        </svg>
      </button>

      <button
        (click)="handleFilterPopup()"
        class="filter-button d-none d-md-flex"
        data-bs-toggle="tooltip"
        data-bs-placement="bottom"
        data-bs-title="Filters"
      >
        <svg class="filter-icon">
          <use xlink:href="icons.svg#filter-icon"></use>
        </svg>
      </button>

      <!-- Toggle Button for smaller screens -->
      <button
        id="toggleSmallScreenButton"
        class="btn btn-primary toggle-small-screen-buttons d-md-none"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#smallScreenButtons"
        aria-expanded="false"
        aria-controls="smallScreenButtons"
        (click)="handleSmallScreenButtons()"
      >
        <svg
          class="new-chat-icon"
          [style.transform]="
            showSmallScreenButtons ? 'rotate(180deg)' : 'rotate(0deg)'
          "
          style="transition: transform 0.3s ease"
        >
          <use xlink:href="icons.svg#menu-icon"></use>
        </svg>
      </button>

      <!-- Collapsible buttons for smaller screens -->
      <div
        id="smallScreenButtons"
        class="collapse small-screen-buttons-container d-md-none"
        aria-labelledby="toggleSmallScreenButton"
      >
        <button (click)="startNewChat()" class="new-chat-button">
          <svg class="new-chat-icon">
            <use xlink:href="icons.svg#new-chat-icon"></use>
          </svg>
        </button>

        <button (click)="handleFilterPopup()" class="filter-button">
          <svg class="filter-icon">
            <use xlink:href="icons.svg#filter-icon"></use>
          </svg>
        </button>
      </div>

      <!-- Chat Interface -->
      <div class="col-md-12" style="position: relative">
        <div class="d-flex justify-content-center align-items-center">
          <!-- Card Container (Visible when showDialogBox is false) -->
          <div
            *ngIf="!showDialogBox"
            class="fade-in-animation container d-flex justify-content-center flex-column align-items-center"
            style="height: calc(-88px + 100dvh - 66px) !important"
          >
            <!-- style="height: calc(-88px + 100dvh - 66px) !important" -->
            <img
              src="/images/logo-no-bg.png"
              alt="Logo"
              class="mb-4 d-none d-md-block"
              style="max-width: 100px"
            />
            <div class="row gx-3 gy-3 justify-content-center">
              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <app-card
                  [text]="
                    opinionId
                      ? 'Provide a list of all citations referenced in this case.'
                      : 'United Parcel Service, Inc. v. Flores-Galarza'
                  "
                  (cardClick)="handleCardClick($event)"
                ></app-card>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <app-card
                  [text]="
                    opinionId
                      ? 'Who are the judges and attorneys, and what is the name of the court?'
                      : 'Seeking compensation for time spent donning and doffing gear during work shifts at poultry processing plants.'
                  "
                  (cardClick)="handleCardClick($event)"
                ></app-card>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <app-card
                  [text]="
                    opinionId
                      ? 'Provide a one-paragraph summary.'
                      : 'What procedural decision did the Montana Supreme Court make in State of Montana v. Christopher Leigh Canal-Hughes, Case Number DA 23-0544, and how might it impact the appeal process? '
                  "
                  (cardClick)="handleCardClick($event)"
                ></app-card>
              </div>

              <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <app-card
                  [text]="
                    opinionId
                      ? 'What is the outcome of this case? Explain in detail.'
                      : 'Search Docket number S-03-1316'
                  "
                  (cardClick)="handleCardClick($event)"
                ></app-card>
              </div>
            </div>
          </div>

          <!-- Dialog Box: Visible when a message is sent -->
          <div *ngIf="showDialogBox" class="container mt-3">
            <div *ngFor="let message of messages" class="row mb-4">
              <!-- User's Sent Message -->
              <div class="col-12 d-flex justify-content-end">
                <div
                  style="
                    color: #e5e5e5;
                    background-color: #2f2f2f;
                    border-radius: 20px;
                  "
                  class="p-3 shadow-sm"
                >
                  <p class="mb-0">
                    {{ message.sentMessage }}
                  </p>
                </div>
              </div>

              <!-- Response and Loading Section -->
              <div class="col-12 mt-2">
                <div class="p-3 rounded shadow-sm">
                  <div *ngIf="message.loading" class="text-muted">
                    <div class="text-center mb-3 d-flex align-items-center">
                      <img
                        src="/images/logo-no-bg.png"
                        class="me2"
                        alt="Logo"
                        style="width: 40px"
                      />
                    </div>
                    <div
                      *ngIf="message.uuid === activeUUID && showFeedback"
                      [ngClass]="{ 'fade-in-animation': triggerFade }"
                      class="feedback"
                    >
                      {{ feedbackMessage }}
                    </div>
                  </div>

                  <div *ngIf="!message.loading">
                    <div class="text-center mb-3 d-flex align-items-center">
                      <img
                        src="/images/logo-no-bg.png"
                        class="me2"
                        alt="Logo"
                        style="width: 40px"
                      />
                    </div>
                    <p class="mb-0" style="color: #e5e5e5">
                      <span [id]="'api-' + message.uuid" [innerHTML]=""></span>
                    </p>
                    <div
                      *ngIf="message.uuid === activeUUID && showFeedback"
                      class="feedback"
                    >
                      {{ feedbackMessage }}
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                      <button
                        *ngIf="
                          !isCompareCase &&
                          !isDocChat &&
                          message.showCompareButton
                        "
                        (click)="loadCompareCasePage(message.uuid)"
                        class="compare-button"
                      >
                        Compare Cases
                      </button>

                      <button
                        (click)="copyAnswer(message)"
                        class="copy-button"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Copy"
                      >
                        <svg>
                          <use xlink:href="icons.svg#copy-icon"></use>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Chat Box Component (Triggers onMessageReceived when message is sent) -->
          <div class="overlay-layer">
            <div class="center-container">
              <app-chat-box
                *ngIf="!isCompareCase"
                style="pointer-events: auto"
                (messageSent)="onMessageReceived($event)"
                [loading]="loading"
                (sendClick)="handleSendClick()"
              ></app-chat-box>
            </div>
          </div>

          <!-- Guest User Popup -->
          <div *ngIf="showGuestPopup">
            <app-popup
              [onHandleGuestPopup]="handleGuestPopup.bind(this)"
              [onLogin]="login.bind(this)"
            ></app-popup>
          </div>
          <!-- Filter Popup -->
          <div *ngIf="showFilterPopup">
            <app-filter-popup
              [onHandlePopup]="handleFilterPopup.bind(this)"
              (selectedCourtsChanged)="onSelectedCourtsChange($event)"
              (dateRangeChanged)="onDateRangeChange($event)"
            >
            </app-filter-popup>
          </div>
          <!-- Compare Popup -->
          <div *ngIf="showComparePopup">
            <app-compare-popup
              [onHandleComparePopup]="handleComparePopup.bind(this)"
            ></app-compare-popup>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
