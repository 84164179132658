<div class="chat-box-container">
  <!-- <button class="paper-clip-button">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="white"
      class="paper-clip-icon"
    >
      <path
        d="M8.28 17.97a3.75 3.75 0 0 1-5.3-5.3l8.06-8.06a5.25 5.25 0 0 1 7.43 7.43l-6.42 6.42a2.25 2.25 0 0 1-3.18-3.18l6.41-6.42a.75.75 0 0 1 1.06 1.06l-6.42 6.42a.75.75 0 0 0 1.06 1.06l6.42-6.42a3.75 3.75 0 1 0-5.3-5.3L4.03 13.73a2.25 2.25 0 0 0 3.18 3.18l6.42-6.41a.75.75 0 1 1 1.06 1.06l-6.42 6.42Z"
      />
    </svg>
  </button> -->

  <div style="width: 24px; height: 24px"></div>

  <textarea
    class="chat-input"
    placeholder="Message Docketchat"
    [(ngModel)]="message"
    (keydown)="onKeyDown($event)"
    (input)="autoGrow($event)"
    rows="1"
  ></textarea>

  <!-- Button enabled only if message has value -->
  <button
    class="send-button"
    [disabled]="!message.trim() && !loading"
    (click)="handleButtonClick()"
  >
    <!-- Change the SVG based on the loading state -->
    <ng-container *ngIf="loading; else normalIcon">
      <!-- Square icon during loading -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 20 20"
        fill="black"
        class="loading-square-icon"
      >
        <rect x="4" y="4" width="12" height="12" />
      </svg>
    </ng-container>

    <!-- Normal send icon -->
    <ng-template #normalIcon>
      <svg
        clip-rule="evenodd"
        fill-rule="evenodd"
        stroke-linejoin="round"
        stroke-miterlimit="2"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m18.787 9.473s-4.505-4.502-6.259-6.255c-.147-.146-.339-.22-.53-.22-.192 0-.384.074-.531.22-1.753 1.753-6.256 6.252-6.256 6.252-.147.147-.219.339-.217.532.001.19.075.38.221.525.292.293.766.295 1.056.004l4.977-4.976v14.692c0 .414.336.75.75.75.413 0 .75-.336.75-.75v-14.692l4.978 4.978c.289.29.762.287 1.055-.006.145-.145.219-.335.221-.525.002-.192-.07-.384-.215-.529z"
          fill-rule="nonzero"
        />
      </svg>
    </ng-template>
  </button>
</div>
