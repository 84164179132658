<!-- <footer class="footer">
  <div class="container text-center">
    <span>DocketChat can make mistakes. Please check important info.</span>
  </div>
</footer> -->

<footer class="footer text-center fixed-bottom">
  <div class="container">
    <p class="mb-0 footer-text">
      DocketChat can make mistakes. Please check important info.
    </p>
  </div>
</footer>
