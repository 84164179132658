<div class="fade-in">
  <main class="main">
    <div class="container">
      <div
        class="custom-height row justify-content-center align-items-center vh-100"
      >
        <!-- Logo Column -->
        <div class="col-md-4 d-none d-md-flex justify-content-center">
          <img src="images/logo-no-bg.png" alt="Logo" class="img-fluid" />
        </div>

        <!-- Divider -->
        <div class="col-md-1 d-none d-md-block">
          <div class="divider"></div>
        </div>

        <!-- Button Column -->
        <div class="col-12 col-md-4">
          <div class="d-grid gap-3">
            <a
              [routerLink]="'/chat'"
              class="btn btn-primary btn-block home-btn d-flex"
            >
              <svg class="icon">
                <use xlink:href="icons.svg#chat-icon"></use>
              </svg>
              Chat</a
            >
            <a
              [routerLink]="'/pricing'"
              class="btn btn-primary btn-block home-btn d-flex"
            >
              <svg class="icon">
                <use xlink:href="icons.svg#pricing-icon"></use>
              </svg>
              Pricing</a
            >
            <a
              [routerLink]="'/faq'"
              class="btn btn-primary btn-block home-btn d-flex"
            >
              <svg class="icon">
                <use xlink:href="icons.svg#faq-icon"></use>
              </svg>
              FAQ</a
            >
            <a
              [routerLink]="'/about'"
              class="btn btn-primary btn-block home-btn d-flex"
            >
              <svg class="icon">
                <use xlink:href="icons.svg#about-icon"></use>
              </svg>
              About</a
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
